import { makeAPIRequest } from "@/app/api/apiWrapper";
import { Button } from "@headlessui/react";
import {
  BuildingOfficeIcon,
  CogIcon,
  CubeIcon,
  NewspaperIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/navigation";

interface OmniSearchResultProps {
  result: any;
}

const OmniSearchResult: React.FC<OmniSearchResultProps> = ({ result }) => {
  const router = useRouter();

  const loginAs = async (id: number) => {
    await makeAPIRequest(`/user/${id}/login`, "GET");
    router.push("/login?authenticated=1");
  };

  if (result._index === "user") {
    return (
      <Link href={`/admin/user/${result._id}`}>
        <div className="flex items-center rounded-md p-1.5 hover:bg-gray-50 hover:cursor-pointer">
          <span className="mr-2.5 h-5 w-5 flex-none stroke-slate-400">
            <UserCircleIcon />
          </span>
          {result._source.firstName} {result._source.lastName}
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current mx-2">
            <circle cx="1" cy="1" r="1"></circle>
          </svg>{" "}
          {result._source.email}
          <Button
            className="ml-auto text-blue-600 hover:underline pl-2"
            onClick={() => loginAs(result._id)}
          >
            Login As
          </Button>
        </div>
      </Link>
    );
  }

  if (result._index === "application") {
    return (
      <Link
        href={`/company/${result._source.companyId}/application/${result._id}`}
      >
        <div className="flex items-center rounded-md p-1.5 hover:bg-gray-50 hover:cursor-pointer">
          <span className="mr-2.5 h-5 w-5 flex-none stroke-slate-400">
            <NewspaperIcon />
          </span>
          <span className="overflow-hidden whitespace-nowrap">
            {result._source.name}
          </span>
          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current mx-2">
            <circle cx="1" cy="1" r="1"></circle>
          </svg>{" "}
          {result._source.competition}
        </div>
      </Link>
    );
  }

  if (result._index === "company") {
    return (
      <Link href={`/company/${result._id}/information`}>
        <div className="flex items-center rounded-md p-1.5 hover:bg-gray-50 hover:cursor-pointer">
          <span className="mr-2.5 h-5 w-5 flex-none stroke-slate-400">
            <BuildingOfficeIcon />
          </span>
          {result._source.name}
        </div>
      </Link>
    );
  }

  if (result._index === "product") {
    return (
      <Link href={`/company/${result._source.companyId}/products`}>
        <div className="flex items-center rounded-md p-1.5 hover:bg-gray-50 hover:cursor-pointer">
          <span className="mr-2.5 h-5 w-5 flex-none stroke-slate-400">
            <CubeIcon />
          </span>
          {result._source.name}
        </div>
      </Link>
    );
  }

  return (
    <div className="flex items-center rounded-md p-1.5">
      <span className="mr-2.5 h-5 w-5 flex-none stroke-slate-400"></span>
      {result._index}
      <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current mx-2">
        <circle cx="1" cy="1" r="1"></circle>
      </svg>{" "}
      {result._id}
    </div>
  );
};
export default OmniSearchResult;
